@import "style/global";

.overview_card {
  flex-direction: column;

  flex-basis: 250px;
  flex-grow: 1;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;

  height: 100px;
  border: 1px solid $card-outline;
  border-radius: 4px;
  color: $black;

  .strong {
    font-weight: bold;
  }
}
