.card h1 {
  font-size: 16px;
  margin-bottom: 35px;
}

table {
  border-collapse: collapse;
  width: 100%;
  height: 90%;
}

.dtable-scroll {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
}

th {
  color: #9fa2b4;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-transform: capitalize;
}

td,
th {
  border-bottom: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
  overflow: hidden;
  min-width: 80px;
}
