body {
  margin: 0;
  padding: 0;
  color: white;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;

  grid-template-areas:
    "header"
    "main"
    "footer";
  height: 100vh;
}

.header {
  grid-area: header;
  background-color: whitesmoke;
}

.aside {
  grid-area: aside;
  background-color: #363740;
}

.aside_header {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.aside_header span {
  color: gray;
}

.main {
  grid-area: main;
  background-color: white;
}
.footer {
  grid-area: footer;
  background-color: whitesmoke;
}

/* flexing header and footer*/
.header,
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #252733;
  padding: 0 15px;
}

/* flexing aside */
.aside {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  position: fixed;
  overflow-y: auto;
  z-index: 2;
  transform: translateX(-245px);
}

.aside.active {
  transform: translateX(0);
}

.aside_list {
  padding: 0;
  margin-top: 45px;
  list-style-type: none;
}

.aside_list-item {
  padding: 20px 20px 20px 40px;
  color: #ddd;
}

.aside_list-item i {
  margin-right: 12px;
}

.aside_list a {
  text-decoration: none;
}

.aside_list-item:hover {
  background-color: #3f4049;
  cursor: pointer;
}

.active {
  background-color: #3f4049;
  border-left: 3px solid #dde2ff;
}

/* Layout for main content overview  and its cards*/
.main_overview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.overview_card {
  flex-basis: 250px;
  flex-grow: 1;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* background-color: seagreen; */
  height: 100px;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
  color: #252733;
}
/* Layout for main-cards section // below main_overview */
.main_cards {
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 500px 200px 300px;
  grid-template-areas:
    "card1"
    "card2"
    "card3";
  grid-gap: 10px;
}
.card {
  padding: 20px;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
  color: #252733;
}

.card:first-child {
  grid-area: card1;
}
.card:nth-child(2) {
  grid-area: card2;
}
.card:nth-child(3) {
  grid-area: card3;
}

/* responsive layout */
@media only screen and (min-width: 750px) {
  .grid-container {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: 50px 1fr 50px;
    grid-template-areas:
      "aside header"
      "aside main"
      "aside footer";
    height: 100vh;
  }

  .aside {
    display: flex;
    flex-direction: column;
    position: relative;
    transform: translateX(0);
  }

  .main_cards {
    margin: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 200px 300px;
    grid-template-areas:
      "card1 card2"
      "card1 card3";
    grid-gap: 10px;
  }
}

.menu-icon {
  position: fixed;
  display: flex;
  top: 2px;
  left: 8px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
  color: black;
}

.header_search {
  margin-left: 24px;
}

.header_avatar {
  cursor: pointer;
}

.aside_close-icon {
  position: absolute;
  visibility: visible;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
@media only screen and (min-width: 750px) {
  .aside_close-icon {
    display: none;
  }
}
