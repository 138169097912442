$primary: #0f4a74;
$secondary: #12456c;

$mod-primary: #363740;
$mod-secondary: #838590;
$mod-navbar-secondary: #3f4049;
$mod-navbar-bookmark: #dde2ff;
$card-outline: #dfe0eb;

$white: #eeee;
$black: #252733;

body {
  margin: 0;
}
